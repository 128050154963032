import { Component, OnInit, ViewChild, TemplateRef, AfterViewInit } from '@angular/core';
import { ProductService } from 'src/app/services/product.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, LegacyLicense, SubscriptionType } from '@applogic/model';
import moment from 'moment';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { LoadingService } from 'src/app/services/loading.interceptor';
import { AccountService } from '../account.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { AngularUtils } from 'src/app/services/angular-utils';
import { ChecklistPopoverComponent } from 'src/app/onboarding-ui/checklist-popover/checklist-popover.component';
import { MatTabGroup } from '@angular/material/tabs';
   export interface InvoiceList{
       invoice_number:string;
       amount:number;
       invoice_date:string;
       invoice_status:string
   }


const LOCAL_STORAGE_SHOW_EXPIRED_SUBSCRIPTIONS = 'applogic/subscriptions/show_expired_subscriptions';

@Component({
    selector: 'app-subscription-page',
    templateUrl: './subscription-page.component.html',
    styleUrls: ['./subscription-page.component.scss']
})
export class SubscriptionPageComponent implements OnInit, AfterViewInit {

    SubscriptionType = SubscriptionType;


    subscriptions: Subscription[];
    subProgress: { [k: string]: number } = {};

    subscriptionType: SubscriptionType;

    isInvoicesVisible: boolean = false;

    orgsSubs: Subscription[] = [];
    subsByOrg: { [k: string]: Subscription[] } = {};
    personalSubs: Subscription[] = [];
    licenses: LegacyLicense[] = [];

    nbOrgSub: number = 0;
    nbPersSub: number = 0;


    @ViewChild(MatTabGroup, { static: false }) tab:MatTabGroup ;
    
    addSubForm = new UntypedFormGroup({
        productCode: new UntypedFormControl('', [Validators.required]),
        expiresOn: new UntypedFormControl('', [Validators.required]),
        seats: new UntypedFormControl('', [Validators.required, Validators.min(1)])
    })

    public showExpiredSubscriptions: boolean = false;

    constructor(readonly products: ProductService, 
                private route: ActivatedRoute, 
                readonly loading: LoadingService,
                private router: Router,
                private ls: LocalStorageService,
                private accountService: AccountService) { }

    ngOnInit(): void {
        this.showExpiredSubscriptions = this.ls.get(LOCAL_STORAGE_SHOW_EXPIRED_SUBSCRIPTIONS);
        
        this.licenses = this.route.snapshot.data.subs.licenses;
        this.subscriptions = this.route.snapshot.data.subs.subscriptions;

        this.subscriptions.forEach((s) => {
            this.subProgress[s.id] = this.getSubRange(s);
            if (s.organization) {
                const list = this.subsByOrg[s.organization.name] = this.subsByOrg[s.organization.name] || [];
                list.push(s);

                this.orgsSubs.push(s);
            }
            else if (s.user) {
                this.personalSubs.push(s);
            }
        });

        let orgs = this.accountService?.currentProfile?.orgs;
        if(orgs && (orgs.length > 0)) {
            this.setTab(SubscriptionType.organization); 
        } else {
            this.setTab(SubscriptionType.individual);
        }

        this.updateCounts();
    }

    ngAfterViewInit() {
        ChecklistPopoverComponent.scrollToQueryAndOpenPopup(this.router, this.route, "sub-");
        ChecklistPopoverComponent.scrollToQueryAndOpenPopup(this.router, this.route, "quo-");
        ChecklistPopoverComponent.scrollToQueryAndOpenPopup(this.router, this.route, "inv-");
    }

    getSubRange(sub: Subscription) {

        let start = sub.createdOn.getTime();
        let end = sub.expiresOn.getTime();

        let current = Date.now();
        

        return ((current - start) / (end - start) * 100);

    }

    /**
     * go to the purchase button
     */
    gotoClientPurchase(){
        this.router.navigate(['/order/client'])
    }

    /**
     * go to the purchase button
     */
    gotoOrganizationPurchase(){
        this.router.navigate(['/order/organization'])
    }

    onShowExpiredCheckedChanged(showExpire: boolean) {
        if(showExpire != this.showExpiredSubscriptions) {
            this.showExpiredSubscriptions = showExpire;
            this.ls.set(LOCAL_STORAGE_SHOW_EXPIRED_SUBSCRIPTIONS, showExpire);
            this.updateCounts();
        }
    }

    checkSubscriptionExpire(expireDate: any) {
        let currentDate = moment();
        let expireDateConst = moment(expireDate);
        if (expireDateConst <= currentDate) {
            return true
        } else {
            return false
        }
    }

    setTab(subscriptionType: SubscriptionType) {
        if(AngularUtils.isDevMode()) console.log("setTab(" + subscriptionType + ")");
        setTimeout(() => {
            if(this.subscriptionType == subscriptionType) return;
    
            if(subscriptionType == SubscriptionType.organization) {
                this.subscriptionType = SubscriptionType.organization;
                this.isInvoicesVisible = true;  
            }
            else if(subscriptionType == SubscriptionType.individual) {
                this.subscriptionType = SubscriptionType.individual;
                this.isInvoicesVisible = true;  
            }
    
        });
    }

    onTabChanged(event: any) {
    }

    onSubscriptionTypeTabChanged(event: any) {
        let clickedIndex = event.index;
        
        if(clickedIndex == 0) {
            this.setTab(SubscriptionType.organization);
        }
        else if(clickedIndex == 1) {
            this.setTab(SubscriptionType.individual);
        }

        this.isInvoicesVisible = true;
    }

    updateCounts() {
        this.nbOrgSub = 0;
        for(const sub of this.orgsSubs) {
            if(this.showExpiredSubscriptions || !this.checkSubscriptionExpire(sub.expiresOn)) {
                this.nbOrgSub++;
            }
        }

        this.nbPersSub = 0;
        for(const sub of this.personalSubs) {
            if(this.showExpiredSubscriptions || !this.checkSubscriptionExpire(sub.expiresOn)) {
                this.nbPersSub++;
            }
        }
    }
}
