import { Component, Input, OnInit } from '@angular/core';
import { OrganizationRole, Status, Subscription, SubscriptionType } from '@applogic/model';
import { ProductService } from 'src/app/services/product.service';
import  moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { SubscriptionCardMenuComponent } from '../subscription-card-menu/subscription-card-menu.component';
import { AccountService } from '../../account.service';
import { AuthService } from 'src/app/auth/auth.service';
import { ChecklistPopoverComponent } from 'src/app/onboarding-ui/checklist-popover/checklist-popover.component';
import { ApplogicUtils } from 'src/app/services/applogic-utils';

@Component({
    selector: 'app-subscription-card',
    templateUrl: './subscription-card.component.html',
    styleUrls: ['./subscription-card.component.scss']
})
export class SubscriptionCardComponent implements OnInit {

    ApplogicUtils = ApplogicUtils;
    // The corresponding card subscription.
    _sub: Subscription;

    @Input()
    set sub(val: Subscription) {
        if(this._sub != val) {
            this._sub = val;

            this.updateAccess();
        }
    }

    get sub() {
        return this._sub;
    }

    canManage: boolean = false;

    @Input() cardMenu: SubscriptionCardMenuComponent;
    
    constructor(readonly productService: ProductService,
                private accountService: AccountService,
                private auth: AuthService) { }

    ngOnInit(): void {

    }

    setCurrent(subscription) {
        ChecklistPopoverComponent.closeAll();
    }

    updateAccess() {
        this.canManage = false;

        
        if(!this.sub.stripeDetails?.subsId) {
            return;
        }

        if((this.sub.type == SubscriptionType.organization) && this.accountService.getSelfRole(this.sub.organization) != OrganizationRole.Administrator) {
            if(this.sub.owner.id == this.auth.user?.id) {
                this.canManage = true;
            }
        }
        else {
            this.canManage = true;
        }
    }

    checkSubscriptionExpire(expireDate: any) {
        let currentDate = moment();
        let expireDateConst = moment(expireDate);
        if (expireDateConst <= currentDate) {
            return true
        } else {
            return false
        }
    }
}
