import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RenewOption, SubAllocationsResponse, Subscription, SubscriptionAllocationMode } from '@applogic/model';
import moment from 'moment';
import { MessageBoxDialogComponent } from 'src/app/core/message-box-dialog/message-box-dialog.component';
import { MessageBoxButton } from 'src/app/core/message-box-dialog/messagebox-button';
import { MessageBoxIcon } from 'src/app/core/message-box-dialog/messagebox-icon';
import { MessageBoxResult } from 'src/app/core/message-box-dialog/messagebox-result';
import { MessageBoxSettings } from 'src/app/core/message-box-dialog/messagebox-settings';
import { SeatService } from 'src/app/services/seat.service';
import { HandleErrorDialogService } from 'src/app/services/handle-error-dialog.service';
import { DialogService } from 'src/app/services/dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { SubscriptionService } from 'src/app/services/subscription.service';

@Component({
    selector: 'app-subscription-mode-panel',
    templateUrl: './subscription-mode-panel.component.html',
    styleUrls: ['./subscription-mode-panel.component.scss']
})
export class SubscriptionModePanelComponent implements OnInit {

    SubscriptionAllocationMode = SubscriptionAllocationMode;

    @Input()
    subscription: Subscription;

    @Input()
    subAlloc: SubAllocationsResponse;

    @Input()
    manualUpdate: boolean = false;

    @Input()
    showLabel: boolean = true;

    @Input()
    compact: boolean = true;

    @Output()
    modeChanged = new EventEmitter<SubscriptionAllocationMode>();

    selectedMode: SubscriptionAllocationMode;

    constructor(private dialogService: DialogService,
        private dialog: MatDialog,
        private subscriptionService: SubscriptionService,
        private errorDialogService: HandleErrorDialogService) { }

    ngOnInit(): void {
    }
    
    canCancelSubscription(subscription: Subscription): boolean {
        if (!subscription) return false;
        return this.checkSubscriptionExpire(subscription.expiresOn) == false && (subscription.renewOption != RenewOption.DoNothing) && (subscription.renewOption != undefined);
    }

    checkSubscriptionExpire(expireDate: any) {
        let currentDate = moment();
        let expireDateConst = moment(expireDate);
        if (expireDateConst <= currentDate) {
            return true
        } else {
            return false
        }
    }

    onAllocModeChange(checked: boolean, subscription: Subscription, mode: SubscriptionAllocationMode) {
        this.selectedMode = mode;

        if(!this.manualUpdate) {
            this.subscriptionService.changeMode(subscription, mode, this.subAlloc, this.dialogService, this.dialog);
        }

        this.modeChanged.emit(mode);
    }
}
