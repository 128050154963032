import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatMenu } from '@angular/material/menu';
import { OrganizationRole, RenewOption, Subscription, SubscriptionType } from '@applogic/model';
import  moment from 'moment';
import { AuthService } from 'src/app/auth/auth.service';
import { PaymentMethodSelectDialogComponent } from 'src/app/order-ui/payment-method-select-dialog/payment-method-select-dialog.component';
import { SeatService } from 'src/app/services/seat.service';
import { HandleErrorDialogService } from 'src/app/services/handle-error-dialog.service';
import { SubscriptionCancelDialogComponent } from '../subscription-cancel-dialog/subscription-cancel-dialog.component';
import { AccountService } from '../../account.service';
import { DialogService } from 'src/app/services/dialog.service';
import { MatDialog } from '@angular/material/dialog';

/**
 * The contextual menu "..." of a SubscriptionCardComponent.
 */
@Component({
    selector: 'app-subscription-card-menu',
    templateUrl: './subscription-card-menu.component.html',
    styleUrls: ['./subscription-card-menu.component.scss'],
    exportAs: "subscriptionMenu"
})
export class SubscriptionCardMenuComponent implements OnInit {

    _sub: Subscription;

    @Input()
    set sub(val: Subscription) {
        if(this._sub != val) {
            this._sub = val;

            this.updateAccess();
        }
    }

    get sub() {
        return this._sub;
    }

    canChangeAllocationMode: boolean = false;
    canChangePaymentMethod: boolean = false;

    @ViewChild(MatMenu, { static: true }) menu: MatMenu;

    @ViewChild('dialogCancelSubscription', { static: true })
    dialogCancelSubscription: TemplateRef<any>;

    constructor(
        private dialogService: DialogService,
        private dialog: MatDialog,
        private seatService: SeatService,
        private errorDialogService: HandleErrorDialogService,
        private authService: AuthService,
        private accountService: AccountService
    ) {

    }

    ngOnInit(): void {
    }

    canCancelSubscription(subscription: Subscription): boolean {
        if (!subscription) return false;
        return this.checkSubscriptionExpire(subscription.expiresOn) == false && (subscription.renewOption != RenewOption.DoNothing) && (subscription.renewOption != undefined);
    }

    checkSubscriptionExpire(expireDate: any) {
        let currentDate = moment();
        let expireDateConst = moment(expireDate);
        if (expireDateConst <= currentDate) {
            return true
        } else {
            return false
        }
    }

    isOwner(subscription: Subscription): boolean {
        if (!subscription) return false;
        
        return this.authService.user?.id == subscription.owner?.id;
    }

    /**
     * open confirmation dialog when 
     * click on cancel subscription
     * @param sub 
     */
    cancelConfirmationDialog(sub: Subscription) {
        SubscriptionCancelDialogComponent.createDialog(this.dialogService, this.dialog, {
            subscription: sub
        }).show();
    }

    updatePaymentMethod(sub: Subscription) {
        PaymentMethodSelectDialogComponent.createDialog(this.dialogService, this.dialog, {
            paymentTarget: sub
        }).show();
    }

    updateAccess() {
        this.canChangeAllocationMode = false;
        this.canChangePaymentMethod = false;

        if((this.sub.type == SubscriptionType.organization) && this.accountService.getSelfRole(this.sub.organization) != OrganizationRole.Administrator) {
            if(this.sub.owner.id == this.authService.user?.id) {
                this.canChangePaymentMethod = true;
            }
        }
        else {
            this.canChangePaymentMethod = true;
            this.canChangeAllocationMode = true;
        }
    }
}
